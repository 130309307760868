/* RULES */

.rules {
	color: #202020;
	border-top: 2px solid #cfd1cf;
	background: radial-gradient(circle, #ebebeb 0%, #cccccc 100%);
}

.rules-container {
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 60px;
	padding-bottom: 100px;
	gap: 50px;
}

.rules-title {
	font-size: 40px;
	color: #367c5e;
}

.rules-text {
	font-size: 19px;
	line-height: 1.6;
	padding: 0 20px;
}

.rules-text li {
	padding: 10px 0;
}

.rules-text li::marker {
	font-weight: bold;
}

.rules-text ol {
	padding: 0 30px;
}

@media (max-width: 1280px) {
	.rules-text {
		font-size: 17px;
		line-height: 1.4;
		max-width: 80%;
	}
}

@media (max-width: 980px) {
	.rules-text {
		font-size: 16px;
		line-height: 1.3;
		max-width: 90%;
	}
}

@media (max-width: 650px) {
	.rules-title {
		font-size: 36px;
	}

	.rules-container {
		padding-top: 60px;
		padding-bottom: 60px;
		gap: 40px;
		min-height: auto;
	}

	.rules {
		min-height: auto;
	}
}

@media (max-width: 576px) {
	.rules-text {
		font-size: 15px;
		line-height: 1.2;
		max-width: 95%;
	}
}

@media (max-width: 485px) {
	.rules-text {
		font-size: 15px;
		max-width: 98%;
	}
}

@media (max-width: 400px) {
	.rules-text {
		font-size: 14px;
		max-width: 100%;
	}

	.rules-container {
		padding-top: 40px;
		padding-bottom: 40px;
		gap: 30px;
	}
}
