/* ABOUT */

.about {
	position: relative;
	color: #ced8df;
	background-color: #1f282f;
	background: linear-gradient(135deg, #1f282f, #1C394D);
	background: linear-gradient(135deg, #141c22, #152E40);
	border-top: 3px solid #344049;
}

.about-container {
	min-height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 60px;
}

.about-title {
	font-size: 40px;
	margin-bottom: 50px;
	color: #479f79;
}

.about-card {
	display: flex;
	gap: 60px;
}

.about-photo {
	border-radius: 50%;
	overflow: hidden;
	max-width: 550px;
	max-height: 550px;
	box-shadow: 0px 0px 0px 4px #253f52;
	border: 4px solid transparent;
	align-self: center;
	pointer-events: none;
	user-select: none;
	/* box-shadow: 0 0 50px rgba(20, 115, 170, 0.2); */
}

.about-photo img {
	max-width: 100%;
	pointer-events: none;
	user-select: none;
}

.about-text {
	flex-shrink: 999;
	align-self: center;
	font-size: 18px;
	line-height: 1.5;
}

.about-text p {
	padding: 15px 0;
}

@media (max-width: 1200px) {
	.about-text {
		font-size: 16px;
		line-height: 1.4;
	}

	.about-photo {
		max-width: 450px;
		max-height: 450px;
	}
}

@media (max-width: 1024px) {
	.about-text {
		font-size: 15px;
	}

	.about-photo {
		max-width: 50%;
		max-height: 50%;
	}

	.about-card {
		gap: 45px;
	}
}

@media (max-width: 900px) {
	.about-text {
		font-size: 14px;
		line-height: 1.3;
	}

	.about-text p {
		padding: 10px 0;
	}
}

@media (max-width: 799px) {
	.about-container {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.about-card {
		flex-direction: column;
		gap: 30px;
	}

	.about-text {
		max-width: 80%;
		font-size: 15px;
	}
}

@media (max-width: 599px) {
	.about-text {
		max-width: 90%;
		font-size: 15px;
	}

	.about-title {
		margin-bottom: 30px;
	}

	.about-photo {
		max-width: 60%;
		max-height: 60%;
	}
}

@media (max-width: 415px) {
	.about-text {
		max-width: 90%;
		font-size: 14px;
	}

	.about-title {
		margin-bottom: 20px;
	}

	.about-photo {
		max-width: 70%;
		max-height: 70%;
	}
}
