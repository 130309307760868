/* FOOTER */

.footer {
	height: 80px;
	background-color: #222222;
	border-top: 1px solid rgba(185, 185, 185, 0.2);
}

.footer-container {
	position: relative;
	z-index: inherit;
	font-size: 13px;
	color: #f0efed;
}

.social {
	display: flex;
	gap: 20px;
}

.social img {
	max-width: 30px;
	transition: transform .1s ease-in;
}

.social img:hover {
	transform: scale(1.07);
}

.copyright {
	color : #666;
	text-transform: uppercase;
	font-size: 15px;
}

@media (max-width: 700px) {
	.footer {
		height: 120px;
		box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.2);
	}

	.footer-container {
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
		gap: 20px;
	}

	.social img {
		max-width: 40px;
	}
}

@media (max-width: 480px) {
	.social img {
		max-width: 35px;
	}
}
