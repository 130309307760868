.main {
	position: relative;
	min-height: 100vh;
	background-image: url('../../Images/background-3.webp');
	background-position: top left;
	background-repeat: repeat;
	background-attachment: scroll;
	background-size: auto;
}

.main:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(10, 10, 50, 0.75);
	background: linear-gradient(180deg, rgba(41, 41, 41, 0.75) 0%, rgba(25, 65, 95, 0.75) 100%);
}

/* GAME */

.game-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 40px;
	padding-top: calc(150px + 80px);
	padding-bottom: 150px;
}

.game-container form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.game-container .form-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	border-radius: 50px;
	transition: box-shadow 0.3s ease-out;
}

.invalid .form-wrapper {
	box-shadow: 0 0 10px #a72626;
}

.error {
	text-align: center;
	font-weight: bold;
	font-size: clamp(0.875rem, 0.783rem + 0.43vw, 1rem);
    max-width: clamp(15.625rem, -0.543rem + 76.09vw, 37.5rem);
	color: #bf4d4d;
	margin-top: 15px;
}

.word-input {
	width: calc(100vw / 2 + 20px);
    max-width: 650px;
	padding: 20px;
	border: none;
	border-radius: 50px 0 0 50px;
	border-right: 2px solid #efe4e4;
	background-color: #fff;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: #303030;
	opacity: 0.8;
	transition: opacity .1s ease-in;
}

.word-input:focus {
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
	opacity: 1;
}

.word-input:hover {
	background-color: #fafafa;
}

.word-input:active,
.word-input:focus {
	outline: none;
}

.word-input[disabled] {
	background-color: #d4d4d4;
	cursor: not-allowed;
}

.submit {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 800;
	color: #fafafa;
	text-transform: uppercase;
	border: 0;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	padding: 20px;
	background-color: #2a6149;
	transition: background-color .2s ease-in-out;
	cursor: pointer;
}

.submit:hover {
	background-color: #367c5e;
}

.submit[disabled] {
	background-color: #5d756b;
	color: #c7c7c7;
	cursor: not-allowed;
}

.send-icon {
	font-size: 19px;
	color: #214b38;
	text-shadow: 0 1px #367c5e;
	filter: drop-shadow(0 1px #367c5e);
	transition: all .2s ease-in-out;
}

.submit:hover .send-icon {
	color: #2a6149;
	text-shadow: 0 1px #3f916d;
	filter: drop-shadow(0 1px #3f916d);
	transition: all .2s ease-in-out;
}

.submit[disabled] .send-icon {
	display: none;
}

.submit:not([disabled]) i.spinner {
	display: none;
}

.submit[disabled] i.spinner {
	display: block;
	padding: 0;
	margin: 0;
	width: 19px;
	height: 19px;
	border: 3px solid #335c4a;
	border-top-color: transparent;
	border-radius: 50%;
	animation: rotateSpinner .8s ease-in-out infinite;
}

.word-input,
.submit {
	height: 60px !important;
}

@keyframes rotateSpinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.finish-game {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 800;
	text-transform: uppercase;
	border: 0;
	border-radius: 50px;
	padding: 20px 30px;
	background-color: #973434;
	color: #fafafa;
	transition: background-color .2s ease-in-out, box-shadow .05s ease-in;
	cursor: pointer;
	box-shadow: 0 4px 0 #683131;
}

.finish-game:hover {
	background-color: #aa3b3b;
}

.finish-game:active {
	box-shadow: 0 1px 0 #683131;
}

.finish-game[disabled] {
	background-color: #834848;
	color:rgba(199, 199, 199, 1);
}

.finish-game-icon {
	font-size: 19px;
	color: #752727;
	text-shadow: 0 1px #bd4040;
	filter: drop-shadow(0 1px #bd4040);
	transition: all .2s ease-in-out;
}

.finish-game:hover .finish-game-icon {
	color: #742828;
	text-shadow: 0 1px #cc4949;
	filter: drop-shadow(0 1px #cc4949);
	transition: all .2s ease-in-out;
}

.finish-game[disabled] .finish-game-icon {
	text-shadow: none;
	filter: unset;
	color:rgba(199, 199, 199, 0.5);
}

.messages {
	font-size: 18px;
	text-align: center;
	line-height: 1.6;
	color: #d9d9d9;
}

.user-word {
	font-weight: bold;
	color: #fafafa;
	text-shadow: 0 1px 5px rgba(251, 255, 0, 0.3);
}

.robot {
	padding-top: 15px;
}

.robot-word {
	position: relative;
	font-weight: bold;
	text-decoration: none;
	color: #53b085;
	transition: color .1s ease-in;
}

.robot-word:hover {
	color: #63ce9c;
}

.robot-word::after {
	content: '';
	display: block;
	border: 1px dashed #53b085;
	position: absolute;
	bottom: -2px;
	left: 50%;
	transform: translateX(-50%);
	width: 95%;
	transition: border 0.1s ease-in;
}

.robot-word:hover::after {
	border: 1px dashed #63ce9c;
}

.robot-word.loose {
	color: #973434;
}

.robot-word.loose::after {
	color: #973434;
	border: none;
}

.timer {
	font-weight: bold;
	color: rgba(99, 206, 156, 0.849);
	pointer-events: none;
	user-select: none;
}
  
.timer.danger {
	color: #bd4040;
	animation: timeBlink 1s ease-in-out infinite;
}
  
@keyframes timeBlink {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
}
  
.timer::before {
	position: relative;
	top: 4px;
	margin: 0 4px;
	content: '';
	display: inline-block;
	width: 23px;
	height: 23px;
	background-image: url('../../Images/timer.svg');
	background-position: center;
	background-size: cover;
}
  
@media (max-width: 365px) {
	.timer {
	  display: block;
	}
}

.next-letter {
	font-weight: bold;
	color: #fafafa;
	text-shadow: 0 1px 5px rgba(251, 255, 0, 0.3);
}

.used-words {
	color: #d9d9d9;
	max-width: 1000px;
	text-align: center;
	max-height: calc(100vh / 3);
    overflow-y: auto;
	scrollbar-width: none;
	mask-image: -webkit-linear-gradient(
		top,
		rgba(0, 0, 0, 1) 800px,
		rgba(0, 0, 0, 0) 105%
	);
	-webkit-mask-image: -webkit-linear-gradient(
		top,
		rgba(0, 0, 0, 1) 80px,
		rgba(0, 0, 0, 0) 105%
	);
}

.used-words::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.used-words p {
	line-height: 1.80;
	color: #f0efed;
	font-size: 16px;
}

.used-words-title {
	font-weight: bold;
}

.used-words-body {
	font-style: italic;
	color: #e9e9e9;
}

.score {
	line-height: 1.8;
	font-size: 18px;
}

.score-count {
	font-weight: bold;
}

/* GAME RESULT */

.game-result {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	width: 100%;
}

.game-result img {
	max-width: 350px;
	width: 100%;
	width: clamp(12.5rem, 6.25rem + 31.25vw, 21.875rem);
	pointer-events: none;
	user-select: none;
	opacity: 0.9;
	margin-bottom: 10px;
}

.game-result-message {
	margin-bottom: 5px;
	font-size: 25px;
	font-size: clamp(1.125rem, 0.833rem + 1.46vw, 1.563rem);
	font-weight: bold;
	color: #53b085;
	text-shadow: 0 0 15px rgba(85, 195, 145, 0.8);
}

.game-result-message.loose {
	color: #a72626;
	text-shadow: 0 0 15px #bd4040;
}

.game-result-message + p {
	font-size: 16px;
	font-size: clamp(0.813rem, 0.688rem + 0.63vw, 1rem);
	color: #76add7;
}

@media (max-width: 980px) {
	.used-words {
		max-width: 700px;
	}
}

@media (max-width: 768px) {
	.word-input {
		width: calc(100vw / 2 + 60px);
	}

	.used-words {
		max-width: 600px;
	}

	.game-container {
		padding-top: calc(100px + 80px);
		padding-bottom: 100px;
	}
}

@media (max-width: 660px) {
	.used-words {
		max-width: 500px;
	}

	.tooltip {
		display: none;
	}
}

@media (max-width: 500px) {
	.game-container {
		padding-top: calc(75px + 80px);
		padding-bottom: 75px;
	}

	.used-words {
		max-width: 350px;
		font-size: 13px;
	}

	.used-words p {
		font-size: 15px;
	}
}

@media (max-width: 480px) {
	.word-input {
		width: calc(100vw - 80px);
	}
	
	.submit .send-icon ~ span {
		display: none;
	}

	.submit {
		padding: 22px 25px 21px 20px;
	}

	.send-icon,
	.submit:hover .send-icon{
		filter: none;
		text-shadow: none;
		color: #fafafa;
	}
}

@media (max-width: 412px) {
	.used-words {
		max-width: 350px;
	}

	.used-words p {
		font-size: 14px;
		line-height: 1.7;
	}

	.used-words-title {
		font-size: 15px;
	}
}

@media (max-width: 385px) {
	.word-input::placeholder {
		font-size: clamp(0.813rem, -0.111rem + 4.62vw, 1rem);
	}
}

@media (max-width: 330px) {
	.word-input {
		width: calc(100vw - 90px);
		font-size: 16px;
	}

	.submit {
		padding: 20px 25px 20px 20px
	}
}
